<template>
  <div class="admin-order-alarm">
    <div class="flex flex-col">
      <div class="w-full bg-action-bar px-2 py-2 clearfix">
        <div class="float-right flex flex-wrap">
          <div class="mx-2 mt-2 lg:mt-0">
            <button class="btn-blue-outline mx-1 mt-2 lg:mt-0" @click="getAdminOrderAlarmLog()">
              <BaseIcon icon="undo" />
            </button>
          </div>
          <div class="select-wrapper mx-2 mt-2 lg:mt-0">
            <select class="bg-gray-100 rounded w-full text-sm text-gray-900 focus:outline-none border-b-2 border-gray-300 transition duration-500 px-1 py-1" v-model="selected_customer" @change="filterByCustomer()">
              <option value="">{{ $t("order_log.all_customers") }}</option>
              <option v-for="c in customers" :key="c.customer_id" :value="c.customer_id">{{ c.name }}</option>
            </select>
          </div>
        </div>
      </div>
      <div class="w-full">
        <div class="flex">
          <div class="min-h-screen flex overflow-x-scroll py-12">
            <div v-for="column in columns" :key="column.id" class="bg-gray-50 rounded-lg px-3 py-3 column-width mr-4">
              <p class="text-gray-700 font-semibold font-sans tracking-wide text-sm">{{ column.name }}</p>
              <Draggable :id="column.id" :list="column.order" :animation="200" :emptyInsertThreshold="100" :handle="column.noDrag" :disabled="column.noDrag" :sort="false" :move="moveElement" @end="saveDragOrder" ghost-class="ghost-card" group="order" class="h-full">
                <div v-for="o in column.order" :key="o._id" class="bg-white shadow rounded px-3 pt-3 pb-5 border border-white mt-3" v-bind:class="{ 'cursor-move': !column.noDrag }">
                  <div class="flex justify-between">
                    <p v-if="o.process_order" class="text-gray-700 font-semibold font-sans tracking-wide text-sm hover:text-gray-800">
                      <span class="cursor-pointer" @click.prevent="showAdminOrderLogModal(o)">{{ getCustomerName(o.customer_id) }} - {{ getUnitName(o.unit_id) }}</span>
                      <span class="cursor-pointer" @click.prevent="showAdminOrderAlarmModal(o)">
                        <BaseIcon icon="pencil-alt" class="text-xxs ml-2 text-primary-500" />
                      </span>
                    </p>
                    <p v-else class="text-gray-700 font-semibold font-sans tracking-wide text-sm hover:text-gray-800" @click.prevent="showAdminOrderAlarmModal(o)">{{ getCustomerName(o.customer_id) }} - {{ getUnitName(o.unit_id) }}</p>
                  </div>
                  <div class="w-full">
                    <span class="text-xs text-gray-600 mr-3 font-bold">Skapad</span>
                  </div>
                  <div class="w-full">
                    <span class="text-sm text-gray-600 mr-1">{{ formatDate(o.createdAt) }}</span>
                  </div>
                  <div class="w-full">
                    <span class="text-xs text-gray-600 mr-3 font-bold">Senast ändrad</span>
                  </div>
                  <div class="w-full">
                    <span class="text-sm text-gray-600 mr-1">{{ formatDate(o.updatedAt) }}</span>
                  </div>
                  <div class="w-full">
                    <span class="text-xs text-gray-600 mr-3 font-bold">Förväntas klar</span>
                  </div>
                  <div class="w-full">
                    <span class="text-sm text-gray-600 mr-1">{{ o.delivery_period }}</span>
                  </div>
                  <div class="flex mt-4 justify-between items-center">
                    <div class="px-3 h-6 rounded-full text-xs font-semibold flex items-center" :class="checkDateStatus(o.delivery_period )">
                      <span v-if="o.process_order" @click.prevent="showAdminOrderLogModal(o)" class="cursor-pointer">
                        {{ o.agreement_number }}
                      </span>
                      <span v-else class="cursor-pointer" @click.prevent="showAdminOrderAlarmModal(o)">
                        <BaseIcon icon="pencil-alt" />
                      </span>
                    </div>
                  </div>
                </div>
              </Draggable>
            </div>
          </div>
        </div>
      </div>
    </div>
    <AdminOrderAlarmModal ref="adminOrderAlarmUpdate" @orderUpdated="getAdminOrderAlarmLog()" :unit_id="null" :sellers="sellers" :alarm_centers="alarm_centers" :customer="customer" :unit="unit" :updateInfo="updateInfo" />
    <AdminOrderAlarmLogModal :log_info="log_info" :customers="customers" :units="units" :sellers="sellers" :alarm_centers="alarm_centers" />
  </div>
</template>

<script>
import Draggable from "vuedraggable";
import AdminOrderAlarmModal from "@/components/modal/admin_order_alarm";
import AdminOrderAlarmLogModal from "@/components/modal/admin_order_alarm_log";

export default {
  name: "AdminOrderAlarmLog",
  components: {
    Draggable,
    AdminOrderAlarmModal,
    AdminOrderAlarmLogModal,
  },
  title() {
    return `Beställ | SecurCloud`;
  },
  data() {
    return {
      user: this.$store.state.user,
      id: this.$route.params.id,
      sim_operators: ["Telia", "Telia DCP", "Telenor", "Tele2", "Kundens Simkort"],
      customers: [],
      units: [],
      alarm_centers: [],
      sellers: [],
      logs: [],
      selected_customer: "",
      log_info: null,
      // update form
      customer: null,
      unit: null,
      updateInfo: null,
      columns: [
      { name: "Avvakta", id: "pending", noDrag: false, order: [] },
        { name: "Beställning", id: "order_created", noDrag: false, order: [] },
        { name: "Skapa larm", id: "create_alarm", noDrag: false, order: [] },
        { name: "Config", id: "config", noDrag: false, order: [] },
        { name: "Provlarma", id: "test_alarm", noDrag: false, order: [] },
        { name: "Kontroll", id: "quality_control", noDrag: false, order: [] },
        { name: "Packas", id: "packing", noDrag: false, order: [] },
        { name: "Färdig", id: "complete", noDrag: false, order: [] },
      ],
    };
  },

  methods: {
    async getAdminOrderAlarmLog() {
      try {
        let response = await this.axios.get(`${process.env.VUE_APP_SERVER_URL}/admin/unit/order-alarm/logs`);
        this.logs = response.data.order_alarm_logs;
        this.filterByCustomer();
      } catch (error) {
        this.handleError(error);
      }
    },

    generateOrderColumn(order_alarm_logs) {
      let columns = [
        { name: "Avvakta", id: "pending", noDrag: false, order: [] },
        { name: "Beställning", id: "order_created", noDrag: false, order: [] },
        { name: "Skapa larm", id: "create_alarm", noDrag: false, order: [] },
        { name: "Config", id: "config", noDrag: false, order: [] },
        { name: "Provlarma", id: "test_alarm", noDrag: false, order: [] },
        { name: "Kontroll", id: "quality_control", noDrag: false, order: [] },
        { name: "Packas", id: "packing", noDrag: false, order: [] },
        { name: "Färdig", id: "complete", noDrag: false, order: [] },
      ];

      order_alarm_logs.forEach((order) => {
        if (order.stage == "pending") columns[0].order.push(order);
        else if (order.stage == "order_created") columns[1].order.push(order);
        else if (order.stage == "create_alarm") columns[2].order.push(order);
        else if (order.stage == "config") columns[3].order.push(order);
        else if (order.stage == "test_alarm") columns[4].order.push(order);
        else if (order.stage == "quality_control") columns[5].order.push(order);
        else if (order.stage == "packing") columns[6].order.push(order);
        else if (order.stage == "complete") columns[7].order.push(order);
      });

      this.columns = columns;
    },

    getCustomers() {
      this.axios
        .get(`${process.env.VUE_APP_SERVER_URL}/customer/`)
        .then((response) => {
          this.customers = response.data.data;
        })
        .catch((error) => {
          this.handleError(error);
        });
    },

    getUnits() {
      this.axios
        .get(`${process.env.VUE_APP_SERVER_URL}/units/`)
        .then((response) => {
          this.units = response.data.data;
        })
        .catch((error) => {
          this.handleError(error);
        });
    },

    getSellersResellers() {
      this.axios
        .get(`${process.env.VUE_APP_SERVER_URL}/seller/reseller`)
        .then((response) => {
          this.sellers = response.data.data;
        })
        .catch((error) => {
          this.handleError(error);
        });
    },

    getAlarmCentres() {
      this.axios
        .get(`${process.env.VUE_APP_SERVER_URL}/alarm-central`)
        .then((response) => {
          this.alarm_centers = response.data.data;
        })
        .catch((error) => {
          this.handleError(error);
        });
    },

    moveElement(evt) {
      let currentNode = evt.to;
      currentNode.style.opacity = 1;
      
      let nodes = ["order_created", "pending", "create_alarm", "config", "test_alarm", "quality_control", "packing", "complete"];
      nodes.forEach((id) => {
        if (currentNode.id != id) document.getElementById(id).style.opacity = 1;
        
      });
    },

    async saveDragOrder(evt) {
      let nodes = ["order_created", "pending", "create_alarm", "config", "test_alarm", "quality_control", "packing", "complete"];
      nodes.forEach((id) => {
        document.getElementById(id).style.opacity = 1;
      });

      try {
        let order = evt.item._underlying_vm_;

        let to = evt.to.id;
        let from = evt.from.id;
        if (to == from) return;

        let i = _.findIndex(this.columns, { id: to });
        if (i > -1) {
          this.columns[i].order = this.columns[i].order.filter((item) => item._id !== order._id);
          this.columns[i].order.unshift(order);
        }

        let data = {
          stage: to,
          pipedrive_deal_id: order.pipedrive_deal_id,
        };

        // eslint-disable-next-line
        let response = await this.axios.put(`${process.env.VUE_APP_SERVER_URL}/order-alarm/${order._id}`, data);
        this.getAdminOrderAlarmLog();
      } catch (error) {
        this.handleError(error);
      }
    },

    filterByCustomer() {
      if (this.selected_customer != "") {
        let f = _.filter(this.logs, { customer_id: this.selected_customer });
        this.generateOrderColumn(f);
      } else {
        this.generateOrderColumn(this.logs);
      }
    },

    getCustomerName(customer_id) {
      let c = _.find(this.customers, { customer_id: customer_id });
      if (c) return c.name;
      else return "";
    },

    getUnitName(unit_id) {
      let u = _.find(this.units, { unit_id: unit_id });
      if (u) return u.name;
      else return "";
    },

    getSellerName(seller_id) {
      let s = _.find(this.sellers, { _id: seller_id });
      if (s) return s.name;
      else return "";
    },

    getAlarmCenterName(alarm_center_id) {
      let a = _.find(this.alarm_centers, { _id: alarm_center_id });
      if (a) return a.name;
      else return "";
    },

    showAdminOrderAlarmModal(log) {
      var vm = this;

      let c = _.find(this.customers, { customer_id: log.customer_id });
      if (c) this.customer = c;

      let u = _.find(this.units, { unit_id: log.unit_id });
      if (u) this.unit = u;

      this.updateInfo = log;

      this.$nextTick().then(() => {
        vm.$refs.adminOrderAlarmUpdate.setupOrderForm();
      });
      this.$modal.show("modal-admin-order-alarm");
    },

    showAdminOrderLogModal(log) {
      this.log_info = log;
      this.$modal.show("modal-admin-order-alarm-log");
    },

    formatDate(date) {
      return this.moment(date).format("YYYY-MM-DD");
    },
    checkDateStatus(date) {
      let expirationDate = this.moment(date);
      let diff = (expirationDate.diff(this.todaysDate, "days"));
   
      if (diff <= 9 && diff > 2) {
        return 'bg-yellow-500 text-gray-100';
      } else if (diff >= 0 && diff <= 2) {
        return 'bg-red-600 text-gray-100';
      } else if (diff <= 1) {
        return 'bg-black text-gray-100';
      } else {
        return 'bg-primary-100'; 
      }
    }
  },
  computed: {
    todaysDate() {
      return this.moment(new Date());
    },
    //if I use computed columns() then the data assignment to columns() is not working which is normal,
    //but without computed columns() the translation is not working, need to find a way.
    // columns() {
    //   return [
    //     { name: "‎", id: "create_pending", noDrag: true, order: [] },
    //     { name: this.$t("order_log.ordering"), id: "order_created", noDrag: false, order: [] },
    //     { name: this.$t("order_log.create_alam"), id: "create_alarm", noDrag: false, order: [] },
    //     { name: this.$t("order_log.config"), id: "config", noDrag: false, order: [] },
    //     { name: this.$t("order_log.sample_alarm"), id: "test_alarm", noDrag: false, order: [] },
    //     { name: this.$t("order_log.control"), id: "quality_control", noDrag: false, order: [] },
    //     { name: this.$t("order_log.packed"), id: "packing", noDrag: false, order: [] },
    //     { name: this.$t("order_log.finished"), id: "complete", noDrag: false, order: [] },
    //   ];
    // },
  },
  created() {
    this.getCustomers();
    this.getUnits();
    this.getSellersResellers();
    this.getAlarmCentres();
    this.getAdminOrderAlarmLog();
    this.setPageTitle(`Beställ`, "admin_order_alarm_log");
  },
};
</script>
